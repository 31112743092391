import React, { useEffect, useState } from "react";

export default function Editcatalougefrom({ setCatalougeModule }) {
  return (
    <>
      {/* <button onClick={()=>{
console.log("flase behviourt");
setCatalougeModule(false)

      }} className="py-4 bg-red-500">
        toggle
      </button> */}

      <div className="fade-in-left flex justify-center">
        <form>
          <div className="form_body">
            <h1 className="text-center font-all font-extrabold text-[23px] text-color lg:relative mt-2 mb-3">
              Edit Catalouge
            </h1>
            <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem]">
              <label
                htmlFor="postTitle"
                className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Name
              </label>
              <input
                type="text"
                placeholder="Write here..."
                name="name"
            
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
              />
            </div>

            <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[32rem]">
              <label
                htmlFor="postTitle"
                className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content"
              >
                Price
              </label>
              <input
                type="number"
                placeholder="Write here..."
                name="Price"
          
                className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
              />
            </div>



            {/* img field here  */}
            <div className="flex items-center justify-center w-full my-4">
              <label
                // style={{
                //   display: selectedImage ? 'none' : 'block'}}

                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-8 h-8 mb-4 text-gray-500 shark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 shark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 shark:text-gray-400">
                    SVG, PNG, JPG, or GIF (MAX. 800x400px)
                  </p>
                </div>
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  // onChange={handleImageChange}
                />
              </label>
              {/* {selectedImage && (
    <div className="flex items-center  w-auto p-[10px] h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600">
   
      <img
      className="w-full h-44 mx-auto "
        src={selectedImage}
        alt="Selected"
      
      />
    </div>
  )} */}
            </div>

            {/* img field end here  */}

            <div className="cooltextarea flex flex-col w-fit-content static max-w-240 lg:w-[32rem] mb-4">
              <label
                htmlFor="description"
                className="text text-sm text-black w-max rounded-md bg-white relative top-2.5 ml-2 px-1 w-fit-content"
              >
                Description
              </label>
              <textarea
                placeholder="Write here..."
                name="description"
                rows="8"
                // value={formData.description}
                // onChange={handleInputChange}
                className="textarea px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px]"
              />
            </div>

            <div class="button-action flex flex-col items-center mt-[3.5rem]">
                  <button
                    // type="submit"
                    class=" bg-[#CB2229] text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative"
                  >
                    Submit Regstration{" "}
                  </button>

                  {/* <> */}
                    <button 
                    
                    onClick={(event)=>{
event.preventDefault()
setCatalougeModule()

                    }}
                    
                    class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
                  cancel 
                    </button>
                  {/* </Link> */}
                </div>

          </div>
        </form>
      </div>
    </>
  );
}
