import React from 'react'

function AddofferForm({setOfferModule}) {
  return (
 <>
   <div className="form">
          <form>
            <div className="flex flex-row lg:gap-[9rem] mt-[3rem] mb-[2rem] fade-in-left">
              {/* 1 */}
              <div className="form-input flex flex-col  w-[47%]">
                <h1 className="font-all font-semibold text-center">
               Add OFFER
                </h1>
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[7rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                 Offer name
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="projectname"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
         
                  />
                </div>{" "}
                {/* 2  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[7rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                 offer price 
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="companyname"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                
                  />
                </div>{" "}
                {/*  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px]  ml-[2.1rem] ">
                  <label
                    htmlFor="input"
                    className=" text text-sm  text-black w-[6rem] rounded-md  bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    cupon code 
                  </label>
                  <input
                    type="text"
                    placeholder="Write here..."
                    name="email"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
                 
                  />
                </div>{" "}
                {/*  */}
                {/* 5  */}
        
                {/*  */}
                <div className="coolinput flex flex-col w-fit-content static max-w-240 lg:w-[541px] ml-[2.1rem]">
                  <label
                    htmlFor="input"
                    className="text text-sm text-black w-max rounded-md bg-[white] relative top-2.5 ml-2 px-1 bg- w-fit-content text-center"
                  >
                    offer description
                  </label>
                  <textarea
                    placeholder="Write here..."
                    name="projectdetails"
                    rows="7"
                    className="input px-2 py-3 text-sm border-[1px] border-[#738482] rounded-md focus:border-[1px] p-[10px]"
        
                  ></textarea>
                </div>
                {/*  */}
              </div>

              {/* 2 */}

              <div className="inline-flex flex-col mt-[4rem] lg:gap-[3rem]">
                {/*  */}

                <div className="flex items-center justify-center w-full">
  <label
  // style={{
  //   display: selectedImage ? 'none' : 'block'}}
  
    htmlFor="dropzone-file"
    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 shark:hover:bg-bray-800 shark:bg-gray-700 hover:bg-gray-100 shark:border-gray-600 shark:hover:border-gray-500 shark:hover:bg-gray-600"
  >
    <div className="flex flex-col items-center justify-center pt-5 pb-6">
      <svg
        className="w-8 h-8 mb-4 text-gray-500 shark:text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 16"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
        />
      </svg>
      <p className="mb-2 text-sm text-gray-500 shark:text-gray-400">
        <span className="font-semibold">Click to upload</span> or drag and drop
      </p>
      <p className="text-xs text-gray-500 shark:text-gray-400">
        SVG, PNG, JPG, or GIF (MAX. 800x400px)
      </p>
    </div>
    <input
      id="dropzone-file"
      type="file"
      className="hidden"

    />
  </label>

</div>

                {/*  */}

                {/* buttin action */}

              
                <div class="button-action flex flex-col items-center mt-6">
                <button
                  // type="submit"
                  class=" bg-[#CB2229] flex flex-row-reverse justify-center items-center gap-2 text-white rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative"
                  // disabled={loading}
                 
                >
           create offer
                </button>
                {/* <Link to="/create_module_promotion" > */}
                <button
                
                onClick={(e)=>{
                  e.preventDefault()
                  setOfferModule()
                }}
                class="mb-6 border-[1px] border-[#CB2229] text-black rounded-full font-all p-[6px] lg:mt-[0.5rem] w-[18rem]   transform hover:scale-105 duration-500 ease-in-out relative  ">
            cancel
                </button>
                {/* </Link> */}
              </div>
              </div>
              {/*  */}
            </div>
          </form>
        </div>
 
 
 </>
  )
}

export default AddofferForm